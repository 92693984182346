.MediaCard {
	background-color: #202022;
	width: 500px;
	border-radius: 5px;
	transition: all 1s ease-in-out;
}

.MediaCard:hover {
	background-color: #303033;
}

.MediaCardWrapper {
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.MediaTitle {
	color: #FFF;
	font-size: 1rem;
	font-weight: 300;
}

.MediaPublisher {
	color: rgba(255, 255, 255, 0.50);
	font-size: 0.8em;
	font-weight: 300;
}

.MediaDate {
	color: rgba(255, 255, 255, 0.30);
	font-size: 0.75em;
	font-weight: 300;
}

@media (max-width: 720px) {
	.MediaCard {
		width: 95vw;
	}

	.MediaTitle {
		font-size: 0.75em;
	}

	.MediaPublisher {
		font-size: 0.7em;
	}

	.MediaDate {
		font-size: 0.6em;
	}
}

@media (max-width: 1000px) {
	.MediaCard {
		width: 360px;
	}

	
}