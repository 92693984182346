.BrandsWrapper {
	display: grid;
	flex-direction: row;
	gap: 20px;
	border: none;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	margin-top: 50px;
}

.Brands {
	max-width: 1000px;
	gap: 100px;
	margin: auto;
	display: flex;
	flex-direction: column;
}

.BrandsPage {
	border: none;
	background-color: #151515;
	margin-bottom: 180px;
}

.BrandContainer {
	display: flex;
	flex-direction: column;
}

.BrandsHeading {
	color: #FFF;
}

.SocialButtonsWrapper {
	justify-content: center;
}

@media(max-width:720px) {
	.BrandsWrapper{
		grid-template-columns: 1fr 1fr 1fr;
	}

	.Brands{
		gap: 50px;
	}
}

@media(max-width:1000px) {
	.BrandsWrapper{
		grid-template-columns: 1fr 1fr 1fr;
	}
}