span {
	display: block;
	margin-bottom: 10px;
}

.WorkTitle{
	color: #FFF;
	opacity: 75%;
	font-size: 1.05em;
	text-align: center;
}

.Home {
	background-color: #151515;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 1920px;
	background-position: right 20% bottom 50%;
	margin: auto;
}

.IntroductionContainer {
	margin: 75px auto auto auto;
}

.Introduction {
	display: flex;
	flex-direction: column;
	width: 80%;
	height: 90%;
	gap: 35px;
	margin-left: 25px;
	background-image: url(https://ik.imagekit.io/t2vnkmzt25/Asset_12-nobg_1L_p04y2UW.svg?updatedAt=1640699904090);
	background-repeat: no-repeat;
	background-position: right 10% bottom 20%;
	background-size: 48%;
	justify-content: center;
}

.IntroductionText {
	display: flex;
	grid-gap: 30px;
	flex-direction: column;
	width: 40%;
}

.NameIntroduction {
	color: #C1C1C1;
	font-weight: 700;
	font-size: 2.2em;
}

.ProfessionalSummary {
	color: #717171;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 20px;
}

.Buttons{
	display: flex;
	flex-direction: row;
	gap: 25px;
	margin-top: 35px;
	width: 40%;
}

@media(max-width:720px) {
	.Home {
		height: 100vh;
	}

	.IntroductionContainer {
		width: 100%;
		margin: 25px auto auto auto;
	}

	.IntroductionText {
		width: 100%;
		gap: 15px;
	}

	.Introduction {
		background-size: 100%;
		background-position: bottom right;
		margin: auto;
	}

	.ProfessionalSummary{
		text-align: center;
		font-size: 0.75em;
		color: #B8B8B8;
		background-color: rgba(255, 255, 255, 0.025);
		border-radius: 5px;
		padding: 10px;
		/* width: 95vw; */
	}

	.NameIntroduction {
		text-align: center;
	}

	.Buttons {
		display: flex;
		justify-content: center;
		margin-bottom: 100px;
		width: 100%;
	}

	.WorkTitle{
		font-size: 0.75em;
	}
}