.ProjectCardContainer {
	width: 500px;
	margin: auto;
	margin: 5px;
}

.ProjectCard {
	background-color: #202022;
	border-radius: 5px;
	transition: all 1s ease-in-out;
}

.ProjectCard:hover {
	background-color: #303033;
}

.ProjectCategory {
	font-size: 0.75em;
	color: rgba(255, 255, 255, 0.18);
}

.ProjectContent {
	padding: 40px;
}

.ProjectActionWrapper {
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
}

.ProjectStack {
	padding: 5px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 1s;
}

.ProjectStack:hover {
	background-color: #272730;
	border-radius: 25px;
}

.ProjectAction {
	font-size: 0.8em;
	color: rgba(255, 255, 255, 0.20);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.ProjectStackWrapper {
	display: flex;
}

.ProjectImage {
	opacity: 30%;
	width: 20px;
	height: 20px;
	margin: auto;
}

.ProjectContainer {
	display: flex;
	flex-direction: row;
	padding: 0 15px;
}

.ImageContainer {
	width: 60px;
	height: 60px;
	background-color: #476841;
	opacity: 0.33;
	border-radius: 5px;
	margin: 25px;
	margin-right: 50px;
}

.IconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

.StackIcons {
	display: flex;
	flex-direction: row;
	grid-gap: 15px;
	margin-bottom: 10px;
}

.ProjectTitle {
	color: #FFF;
	font-size: 0.9em;
	font-weight: 400;
	margin-top: 25px;
	margin-bottom: 10px;
}

.ProjectDescription {
	font-size: 0.75em;
	color: #7A7979;
	width: 280px;
}

.ProjectStackContainer {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin-top: 30px;
}

@media (max-width: 720px) {
	.ProjectCardContainer {
		width: 95vw;
	}

	.ProjectAction {
		font-size: 0.7em;
	}

	.ImageContainer {
		display: none;
	}

	.ProjectContent {
		padding-left: 20px;
		padding-right: 20px;
		margin: auto;
	}

	.ProjectCard {
		width: 100%;
		margin: auto;
	}

	.ProjectCategory {
		font-size: 0.5em;
	}

	.ProjectTitle {
		font-size: 0.75em;
	}

	.ProjectDescription {
		font-size: 0.7em;
	}
}

@media (max-width: 1000px) {
	.ProjectCardContainer{
		width: 360px;
	}
}