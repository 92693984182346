.Contact {
	/* height: 100vh; */
	/* background-color: #121212; */
	background-image: url(https://ik.imagekit.io/t2vnkmzt25/Asset_10-nobg_vrvumxkP3E.svg?updatedAt=1640699905337);
	background-repeat: no-repeat;
	background-position: left 85% bottom 20px;
	background-size: 30%;
}

.ContactContent {
	padding: 40px;
	margin: auto;
	padding-bottom: 350px;
	max-width: 500px;
	width: 50%;
}

.ContactWrapper {
	display: flex;
	flex-direction: column;
	gap: 80px;
}

.ContactButton {
	background-color: #202022;
	border-radius: 5px;
	border: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding: 10px;
	color: #D6D6D6;
	text-align: left;
	transition: all 1s ease-in-out;
}

.ContactButton:hover{
	background-color: #272730;
}

.MainContactButtons {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.SocialButtons {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 20px;
}

.SocialButton {
	background-color: transparent;
	border: 0;
	color: #CFCFCF;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 10px;
	border-radius: 20px;
	transition: all 1s ease-in-out;
	margin: auto;
}

.SocialButton:hover {
	background-color: #202022;
}

@media(max-width:720px) {
	.Contact {
		background-size: 50%;
	}

	.SectionTitle {
		font-size: 20px;
		padding-left: 20px;
	}

	.ContactText {
		font-size: 18px;
		width: 100%;
	}

	.ContactContent {
		width: 70%;
		padding-bottom: 300px;
	}

	.ContactButtons {
		grid-gap: 20px;
	}

	.SocialButtons{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.SocialButton{
		font-size: 0.6em;
	}

	.ContactButton{
		font-size: 0.6em;
		gap: 12px;
	}
}


@media(max-width:480px) {
	.Contact {
		background-size: 80%;
		background-position: left 40% bottom 5%;
	}

	.ContactText {
		font-size: 14px;
	}

	.ContactButtons {
		grid-gap: 10px;
	}

}