.SecondaryCTA {
    border: #525252 1px solid;
    border-radius: 5px;
    width: 120px;
    height : 40px;
    margin: auto;
    background-color: transparent;
    color: #FFFFFF;
    opacity: 0.87;
    transition: all 0.8s ease-out;
}

.SecondaryCTA:hover{
    border: none;
    background-color: #C1C1C1;
    color: #525252;
}

@media(max-width: 720px){
    .SecondaryCTA{
        width: 75px;
        height: 30px;
        font-size: 0.6em;
    }
}