.BrandInfoWrapper {
	display: flex;
	flex-direction: column;
	background: #151515;
	gap: 25px;
	margin: auto;
	justify-content: center;
	text-align: center;
	align-items: center;
	padding: 15px;
	transition: all 1s ease-in-out;
	border-radius: 10px;
}

.BrandInfoWrapper:hover {
	background-color: #1D1E1B;
}

.BrandInfo {
	color: #7A7979;
	font-size: 0.85em;
	min-width: 80px;
}

.BrandLocation {
	color: #424040;
	font-size: 0.75em;
	height: 40px;
}

.BrandImage {
	max-height: 50px;
	max-width: 120px;
}

.BrandsPage {}

.Brands {
	display: flex;
	margin: auto;
	justify-content: center;
	max-width: 1000px;
}

.BrandContainer {
	margin: auto;
	max-width: 1000px;
}

@media(max-width:720px) {
	.BrandImage{
		max-height: 25px;
		max-width: 80px;
	}

	.BrandInfo{
		font-size: 0.7em;
	}

	.BrandLocation{
		font-size: 0.6em;
	}
}