.PrimaryCTA {
    border: #A1A1A1 1px solid;
    border-radius: 5px;
    width: 120px;
    height : 40px;
    margin: auto;
    background-color: transparent;
    color: #FFFFFF;
    opacity: 0.87;
    transition: all 0.8s ease-out;
}

.PrimaryCTA:hover{
    border: none;
    background-color: #A1A1A1;
    color: #1F1F1F;
}


@media(max-width: 720px){
    .PrimaryCTA{
        width: 75px;
        height: 30px;
        font-size: 0.6em;
    }
}