.Portfolio {
    display: flex;
	margin-top: 180px;
    flex-direction: column;
    background-color: #151515;
    background-image: url(https://ik.imagekit.io/t2vnkmzt25/Asset_11-nobg_3_Ct-pPak.svg?updatedAt=1640699906835);
    background-position: right 20% bottom 20px;
    background-size: 30%;
    background-repeat: no-repeat;
    padding-bottom: 40px;
}

.SectionTitle {
    color: #FFF;
    font-weight: 600;
    font-size: 1.1em;
    text-align: center;
    padding: 25px;
    margin: auto;
}

.PortfolioSwitch{
	color: #FFF;
	display: flex;
	gap: 25px;
	margin: auto;
}

.ProjectCardContianer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-columns: repeat(auto-fill, minmax(480px, 2fr)); */
    gap: 10px;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 200px;
}

@media(max-width:720px) {

    .Portfolio {
        padding-bottom: 100px;
        background-size: 75%;
		background-position: bottom center;
    }

    .SectionTitle {
        font-size: 20px;
        margin: auto;
        padding-left: 20px;
    }

    .ProjectCardContianer {
        display: flex;
        flex-direction: column;
        gap: 10px;
		justify-content: center;
    }
}