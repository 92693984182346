.NavBar {
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: #151515;
	margin: auto;
	display: flex;
	max-width: 1200px;
	justify-content: space-between;
	color: #FFF;
	padding: 20px;
	align-items: center;
}

.NavContent {
	display: flex;
	flex-direction: row;
	grid-gap: 50%;
	text-align: center;
	margin-left: 14px;
	justify-content: center;
}

.NavLogo {
	font-size: 1.2rem;
	padding: 8px 15px;
	color: #FFF;
	background-color: #8E8E8E;
	border-radius: 5px;
}

.NavItemWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.NavItem {
	color: #606060;
	font-size: 0.85em;
	transition: all 1s ease-in-out;
	cursor: pointer;
	margin: auto;
}

.NavItemsContainer {
	display: flex;
	flex-direction: row;
	gap: 60px;
	justify-content: center;
}

.NavItem:hover {
	color: #FFF;
}