body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	background-color: #151515;
}

.SectionTitle {
	color: #FFF;
	font-weight: 600;
	font-size: 1.1em;
	text-align: center;
	padding: 25px;
	margin: auto;
}

.HeadingText {
	color: #737373;
	text-align: center;
}

.HeadingWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	text-align: center;
	margin: auto;
}

.HeadingContainer {
	display: flex;
	flex-direction: column;
}

.Heading {
	color: #FFF;
	margin: auto;
	margin-bottom: 10px;;
}

@media(max-width:720px) {
	.Heading {
		font-size: 20px;
		margin: auto;
		font-size: 0.9em;
		margin-bottom: 5px;
	}

	.HeadingText{
		font-size: 0.75em;
	}
}