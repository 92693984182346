.MediaCards{
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 1000px;
	gap: 20px;
	margin: 25px auto;
	margin-bottom: 150px;
}

a{
	width: fit-content;
	margin: auto;
}

@media (max-width: 720px) {
	.MediaCards{
		width: 95%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 1000px) {

}